body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.legend-color {
  width: 16px;
  height: 16px;
  display: inline-block;
  /* border-radius: 8px; */
}

.mapboxgl-popup {
  font-family: Helvetica, sans-serif;
  align-items: left;
  text-align: left;
  max-width: 400px!important;
  overflow-y: auto;
  max-height: 450px;
}

.mapboxgl-popup-content{
  max-width: 600px;
  overflow-y: auto;
  max-height:450px;
}

.mapboxgl-popup  p{
  margin: 0px;
}